import React, { useState, useEffect } from 'react'
import { Typography, createStyles, withStyles } from '@material-ui/core'

import { getHeroStyles } from '../styles/hero'

const styles = (theme) => {
  const heroStyles = getHeroStyles()
  return createStyles({
    hero: {
      ...heroStyles,
      marginBottom: '2.1875rem',
      [theme.breakpoints.up('md')]: {
        marginBottom: '6.125rem',
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: '7.5625rem',
      },
    },
    title: {
      [theme.breakpoints.up('md')]: {
        textAlign: 'center',
      },
    },
  })
}

const Hero = ({ classes }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    let searchParams = new URLSearchParams(window && window.location.search)
    const hasMobileQuery = searchParams.has('mobile')
    setIsMobile(hasMobileQuery)
  }, [])

  return (
    <section className={classes.hero}>
      <Typography
        variant="h1"
        className={classes.title}
        style={{
          color: isMobile && '#000000',
        }}
      >
        Privacy Policy
      </Typography>
    </section>
  )
}

export default withStyles(styles)(Hero)
