import React from 'react'
import Head from 'react-helmet'
import { Container, createStyles, withStyles } from '@material-ui/core'

import Hero from './Hero'
import Content from './Content'
import Layout from '../components/Layout'

const styles = (theme) => {
  return createStyles({
    container: {
      paddingLeft: '2rem',
      paddingRight: '1.625rem',
      maxWidth: '40.6875rem',
      [theme.breakpoints.up('md')]: {
        padding: 0,
      },
    },
  })
}

const PrivacyPolicy = ({
  classes,
  data: {
    file: {
      childMarkdownRemark: { html },
    },
  },
}) => {
  return (
    <Layout>
      <Head>
        <title>Privacy Policy</title>
      </Head>
      <Container className={classes.container}>
        <Hero />
        <Content html={html} />
      </Container>
    </Layout>
  )
}

export default withStyles(styles)(PrivacyPolicy)
